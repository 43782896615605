<template>
  <div class="button-container">
    <button
      @click="$emit('click')"
      v-if="type == 'standard'"
      :class="{ disabled: disabled }"
    >
      <span>{{ title }}</span>
    </button>
    <button class="cancel" @click="$emit('click')" v-else-if="type == 'cancel'">
      <span>{{ title }}</span>
    </button>
    <button class="hollow" @click="$emit('click')" v-else-if="type == 'hollow'">
      <span>{{ title }}</span>
    </button>
  </div>
</template>

<script>
export default {
  name: "StandardButton",
  props: ["title", "type", "disabled"],
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {},
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.button-container {
  width: 100%;
  button {
    background-color: $twilight;
    border: none;
    color: white;
    height: 48px;
    padding: 0 20px;
    color: white;
    font-size: 16px;
    @include Gilroy-Bold;
    transition: 0.3s;
    width: 100%;

    .disabled {
      background-color: $fog;
    }

    &:hover {
      background-color: $twilightshade;
    }

    &.cancel {
      background-color: transparent;
      color: $twilight;
      @include Gilroy-Regular;
      transition: 0.3s;

      &:hover {
        @include Gilroy-Bold;
      }
    }

    &.hollow {
      background-color: transparent;
      color: $twilight;
      border: 1px solid $twilight;
    }
  }
}
@media (min-width: 992px) {
  .button-container {
    width: auto;
    button {
      width: auto;
    }
  }
}
</style>
