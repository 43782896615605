<template>
  <div v-if="!loading" class="row scores-ties-container">
    <div class="col-12 section">
      <h5>Export scores</h5>
      <div class="content export">
        <p>
          Export hole-by-hole or total scores (includes all the necessary
          details for uploading to PDGA).
        </p>
        <TjingContextMenu
          position="bottom-end"
          icon="button"
          iconText="Choose export"
          :menuItems="exportMenuOptions()"
          @ALL="downloadResults()"
          @HOLEBYHOLE="downloadPoolResults($event, payload)"
        />
      </div>
    </div>
    <div class="col-12 section">
      <h5>Edit scores and stats</h5>
      <div class="content">
        <p class="note">You can only edit scores on rounds that are closed.</p>
      </div>
      <div class="content">
        <select class="players" name="players" v-model="selectedPlayer">
          <option :value="{}">Select player...</option>
          <option
            v-for="player in sortedPlayers(eventData.players)"
            :value="player"
            :key="player.id"
          >
            {{ player.user.firstName }} {{ player.user.lastName }}
          </option>
        </select>
        <select class="rounds" name="rounds" v-model="selectedRound">
          <option :value="{}">Round</option>
          <option
            v-for="(round, roundIndex) in eventData.rounds"
            :value="round"
            :key="'round' + roundIndex"
          >
            R{{ roundIndex + 1 }}
          </option>
        </select>
        <h3 class="d-none d-lg-block">{{ playerTotals.score }}</h3>
      </div>
      <div class="content mb-5" v-if="scoresLoaded">
        <table>
          <thead>
            <tr>
              <th class="hole">Hole</th>
              <th class="score">Score</th>
              <th class="circle-hit"></th>
              <th class="inside-putt"></th>
              <th class="outside-putt"></th>
              <th class="out-of-bounds"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="hole in playedLayout.holes" :key="hole.number">
              <td class="hole">{{ holeDisplayName(hole) }}</td>
              <td class="score">
                <svg
                  @click="decreaseScore(hole)"
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                >
                  <circle class="a" cx="16" cy="16" r="16" />
                  <line class="b" x2="10" transform="translate(11 16)" />
                </svg>
                <div
                  :style="{
                    backgroundColor:
                      $store.state.colors.parColors[parColor(hole)].hex,
                  }"
                >
                  {{ hole.result.score }}
                </div>

                <svg
                  @click="increaseScore(hole)"
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                >
                  <circle class="a" cx="16" cy="16" r="16" />
                  <g transform="translate(-1142 -350)">
                    <line class="b" y2="12" transform="translate(1158 360)" />
                    <line class="b" x2="12" transform="translate(1152 366)" />
                  </g>
                </svg>
              </td>
              <td
                class="circle-hit"
                @click="hole.result.isCircleHit = !hole.result.isCircleHit"
              >
                <img
                  v-if="hole.result.isCircleHit"
                  src="@/assets/img/paid-icon-midnight-desk.svg"
                  alt="Yes"
                />
              </td>
              <td class="inside-putt" @click="toggleInsidePutt(hole.result)">
                <img
                  v-if="hole.result.isInsidePutt"
                  src="@/assets/img/paid-icon-midnight-desk.svg"
                  alt="Yes"
                />
              </td>
              <td class="outside-putt" @click="toggleOutsidePutt(hole.result)">
                <img
                  v-if="hole.result.isOutsidePutt"
                  src="@/assets/img/paid-icon-midnight-desk.svg"
                  alt="Yes"
                />
              </td>
              <td
                class="out-of-bounds"
                @click="hole.result.isOutOfBounds = !hole.result.isOutOfBounds"
              >
                <img
                  v-if="hole.result.isOutOfBounds"
                  src="@/assets/img/paid-icon-midnight-desk.svg"
                  alt="Yes"
                />
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td></td>
              <td>{{ playerTotals.score }}</td>
              <td>{{ playerTotals.circleHits }}</td>
              <td>{{ playerTotals.insidePutts }}</td>
              <td>{{ playerTotals.outsidePutts }}</td>
              <td>{{ playerTotals.obs }}</td>
            </tr>
          </tfoot>
        </table>
        <div class="dnf-dns-switches">
          <span>
            DNF
            <DefaultToggle
              v-model="selectedPlayer.dnf.isDnf"
              @input="toggleDNF(selectedPlayer.dnf.isDnf, true)"
            />
          </span>
          <span>
            DNS
            <DefaultToggle
              v-model="selectedPlayer.dns.isDns"
              @input="toggleDNS(selectedPlayer.dns.isDns, true)"
            />
          </span>
        </div>
        <div class="buttons" v-if="playedLayout.holes.length > 0">
          <StandardButton
            type="hollow"
            title="Cancel"
            @click="cancelEditing()"
          />
          <StandardButton
            type="standard"
            title="Save results"
            @click="saveChanges()"
          />
        </div>
      </div>
    </div>

    <div class="col-12 section tiebreaker-section">
      <h5>Tiebreaker</h5>
      <section>
        <div class="content fixed-height-100">
          <p>
            <b>Tied players</b>
          </p>
          <p>
            Below are the current tied players in this event. Click a player to
            define them as the winner of that tie. This will be reflected on the
            Leaderboard.
          </p>
        </div>
        <div
          class="content division"
          v-for="division in tiebreakerData.tour.divisions"
          :key="division.id"
        >
          <p class="w-100">
            <b>{{ division.name }}</b>
          </p>
          <template v-for="position in tiesInDivision(division)">
            <div class="position" :key="'first' + position.place">
              <p>Position {{ position.place }}</p>
              <p>Score {{ position.score }}</p>
            </div>
            <div
              class="player"
              v-for="player in position.players"
              :key="player.id"
              @click="createTieBreak(player, position)"
            >
              {{ player.user.firstName }} {{ player.user.lastName }}
              <Tiebreaker />
            </div>
          </template>
          <div class="none" v-if="tiesInDivision(division).length == 0">
            <p>-</p>
          </div>
        </div>
      </section>
      <section>
        <div class="content fixed-height-100">
          <p>
            <b>Tiebreaker players</b>
          </p>
          <p>
            Below are the current tiebreaker players. Click a player to remove
            the tiebreak.
          </p>
        </div>
        <div
          class="content division"
          v-for="division in tiebreakerData.tour.divisions"
          :key="division.id"
        >
          <p class="w-100">
            <b>{{ division.name }}</b>
          </p>
          <template v-for="position in tiebreakersInDivision(division)">
            <div class="position" :key="position.id">
              <p>Position {{ position.place }}</p>
            </div>
            <div
              class="player"
              :key="position.id + 'plaer'"
              @click="deleteTieBreak(position.id)"
            >
              {{ position.player.user.firstName }}
              {{ position.player.user.lastName }}
              <Tiebreaked />
            </div>
          </template>
          <div class="none" v-if="tiebreakersInDivision(division).length == 0">
            <p>-</p>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { orderBy } from "lodash";
import DefaultToggle from "@/components/UIElements/DefaultToggle";
import StandardButton from "@/components/UIElements/StandardButton";
import Tiebreaker from "@/assets/icons/Tiebreaker";
import Tiebreaked from "@/assets/icons/Tiebreaked";

import TjingContextMenu from "@/components/TjingContextMenu";

export default {
  name: "EventPlayerScores",
  components: {
    DefaultToggle,
    StandardButton,
    Tiebreaker,
    Tiebreaked,
    TjingContextMenu,
  },
  data() {
    return {
      eventData: {},
      tiebreakerData: {},
      loading: true,
      selectedPlayer: {},
      selectedRound: {},
      playerResults: {},
      playedLayout: { holes: [] },
      scoresLoaded: false,
      statusMessageValue: true,
      showStatusMessage: false,
    };
  },
  computed: {
    playerTotals() {
      let totals = {
        score: 0,
        circleHits: 0,
        insidePutts: 0,
        outsidePutts: 0,
        obs: 0,
      };

      this.playedLayout.holes.forEach((hole) => {
        totals.score += hole.result.score;
        if (hole.result.isCircleHit) totals.circleHits++;
        if (hole.result.isInsidePutt) totals.insidePutts++;
        if (hole.result.isOutsidePutt) totals.outsidePutts++;
        if (hole.result.isOutOfBounds) totals.obs++;
      });

      totals.score == 0 ? (totals.score = " ") : totals.score;
      return totals;
    },
    resultsToUpdate() {
      return this.playedLayout.holes.filter((hole) => hole.result.id != null);
    },
    resultsToCreate() {
      return this.playedLayout.holes.filter(
        (hole) => hole.result.id == null && hole.result.score != 0
      );
    },
  },
  watch: {
    $route: "loadData",
    selectedPlayer: function () {
      if (
        Object.keys(this.selectedPlayer).length !== 0 &&
        Object.keys(this.selectedRound).length !== 0
      ) {
        this.findPlayersPool(this.selectedPlayer, this.selectedRound);
      } else {
        this.playedLayout = { holes: [] };
        this.scoresLoaded = false;
      }
    },
    selectedRound: function () {
      if (
        Object.keys(this.selectedPlayer).length !== 0 &&
        Object.keys(this.selectedRound).length !== 0
      ) {
        this.findPlayersPool(this.selectedPlayer, this.selectedRound);
      } else {
        this.playedLayout = { holes: [] };
        this.scoresLoaded = false;
      }
    },
  },
  methods: {
    exportMenuOptions() {
      let menuOptions = [];

      let noCompletedPool = true;

      this.eventData.rounds.forEach((round, roundIndex) => {
        round.pools.forEach((pool, poolIndex) => {
          if (pool.status == "COMPLETED") {
            noCompletedPool = false;
          }
          menuOptions.push({
            title:
              round.pools.length == 1
                ? `R${roundIndex + 1}`
                : `R${roundIndex + 1}P${
                    this.$store.state.poolConversion[poolIndex]
                  }`,
            disabled: pool.status !== "COMPLETED",
            action: "HOLEBYHOLE",
            payload: pool.id,
          });
        });
      });

      menuOptions = [
        {
          title: "All rounds (total scores)",
          disabled: noCompletedPool,
          action: "ALL",
        },
        ...menuOptions,
      ];

      return menuOptions;
    },
    downloadPoolResults(poolId) {
      this.eventData.rounds.forEach((round, roundIndex) => {
        round.pools.forEach((pool, poolIndex) => {
          if (pool.id == poolId) {
            let fileName = this.eventData.name + "-R" + (roundIndex + 1);

            if (round.pools.length > 1) {
              fileName += "P" + this.$store.state.poolConversion[poolIndex];
            }

            fileName += "-Results.xlsx";

            this.$axios({
              method: "get",
              url: process.env.VUE_APP_ROOT_EXPORT + `pools/${pool.id}/export`,
              headers: {
                Authorization: this.$store.state.user.sessionInfo.token,
              },
              responseType: "blob",
            })
              .then((result) => {
                const url = window.URL.createObjectURL(new Blob([result.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", fileName);
                document.body.appendChild(link);
                link.click();
              })
              .catch(() => {});
          }
        });
      });
    },
    holeDisplayName(hole) {
      if (hole.name != null) {
        return hole.name;
      } else {
        return hole.number;
      }
    },
    sortedPlayers(players) {
      let sorted = orderBy(players, (player) => player.user.firstName, ["asc"]);

      return sorted;
    },
    downloadResults() {
      this.$axios({
        method: "get",
        url:
          process.env.VUE_APP_ROOT_EXPORT +
          `events/${this.$router.currentRoute.params.eventId}/export`,
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        responseType: "blob",
      })
        .then((result) => {
          const url = window.URL.createObjectURL(new Blob([result.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${this.eventData.name}-Results.csv`);
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => {});
    },
    deleteTieBreak(tiebreak) {
      this.$axios({
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        data: {
          query: `
          mutation {
            DeleteEventTiebreak(tiebreakId:"${tiebreak}"){
              id
            }
          }
          `,
        },
      })
        .then(() => {
          this.loadData();
        })
        .catch(() => {});
    },
    createTieBreak(player, position) {
      this.$axios({
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        data: {
          query: `
            mutation{
            	CreateEventTiebreak(playerId:"${player.id}",place:${position.place}){
                id
              }
            }
          `,
        },
      })
        .then(() => {
          this.loadData();
        })
        .catch(() => {});
    },
    tiesInDivision(division) {
      let tieBreakerPositionsinDivison = [];

      this.tiebreakersInDivision(division).forEach((breaker) => {
        tieBreakerPositionsinDivison.push(breaker.place);
      });

      let ties = this.tiebreakerData.ties.filter(
        (place) =>
          place.division.id == division.id &&
          !tieBreakerPositionsinDivison.includes(place.place)
      );

      return ties;
    },
    tiebreakersInDivision(division) {
      let tiebreakers = this.tiebreakerData.tiebreaks.filter(
        (place) => place.player.division.id == division.id
      );

      return tiebreakers.sort((a, b) => (a.place > b.place ? 1 : -1));
    },
    toggleOutsidePutt(result) {
      if (result.isOutsidePutt) {
        result.isOutsidePutt = false;
      } else {
        result.isOutsidePutt = true;
        result.isInsidePutt = false;
      }
    },
    toggleInsidePutt(result) {
      if (result.isInsidePutt) {
        result.isInsidePutt = false;
      } else {
        result.isInsidePutt = true;
        result.isOutsidePutt = false;
      }
    },
    toggleDNF(dnfValue, triggerNotification) {
      if (dnfValue == true) {
        this.toggleDNS(false, false);
      }

      this.$axios({
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        data: {
          query: `
            mutation
              {
                UpdatePlayerDnf(playerId:"${this.selectedPlayer.id}",asRole:TOUR_ADMIN,isDnf:${dnfValue}){
                  id
                  dnf{
                    isDnf
                  }
                }  
              }
            `,
        },
      })
        .then((result) => {
          if (triggerNotification) {
            this.$store.dispatch("showMessage", {
              show: true,
              message: "Saved",
              type: "success",
            });
          }
          this.selectedPlayer.dnf.isDnf =
            result.data.data.UpdatePlayerDnf.dnf.isDnf;
        })
        .catch(() => {
          this.$store.dispatch("showMessage", {
            show: true,
            message: "Error",
            type: "error",
          });

          this.loadData();
        });
    },
    toggleDNS(dnsValue, triggerNotification) {
      if (dnsValue == true) {
        this.toggleDNF(false, false);
      }

      this.$axios({
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        data: {
          query: `
            mutation
              {
                UpdatePlayerDns(playerId:"${this.selectedPlayer.id}",asRole:TOUR_ADMIN,isDns:${dnsValue}){
                  id
                  dns{
                    isDns
                  }
                }  
              }
            `,
        },
      })
        .then((result) => {
          if (triggerNotification) {
            this.$store.dispatch("showMessage", {
              show: true,
              message: "Saved",
              type: "success",
            });
          }
          this.selectedPlayer.dns.isDns =
            result.data.data.UpdatePlayerDns.dns.isDns;
        })
        .catch(() => {
          this.$store.dispatch("showMessage", {
            show: true,
            message: "Error",
            type: "error",
          });

          this.loadData();
        });
    },
    cancelEditing() {
      this.selectedRound = {};
      this.selectedPlayer = {};
      this.scoresLoaded = false;
    },
    saveChanges() {
      for (var l = 0; l < this.resultsToUpdate.length; l++) {
        let hole = this.resultsToUpdate[l];

        this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
            mutation UpdateResult($UpdateResult: UpdateResultInput!){
              UpdateResult(resultId:"${hole.result.id}", asRole:TOUR_ADMIN, input:$UpdateResult){
                id
              }
            }
            `,
            variables: {
              UpdateResult: {
                score: hole.result.score,
                isCircleHit: hole.result.isCircleHit,
                isInsidePutt: hole.result.isInsidePutt,
                isOutsidePutt: hole.result.isOutsidePutt,
                isOutOfBounds: hole.result.isOutOfBounds,
              },
            },
          },
        })
          .then(() => {
            this.$store.dispatch("showMessage", {
              show: true,
              message: "Saved",
              type: "success",
            });
          })
          .catch(() => {
            this.$store.dispatch("showMessage", {
              show: true,
              message: "Error",
              type: "error",
            });
          });
      }

      for (var j = 0; j < this.resultsToCreate.length; j++) {
        let hole = this.resultsToCreate[j];

        let groupId = "";
        let playersPoolsGroups = {};

        for (var i = 0; i < this.selectedRound.pools.length; i++) {
          if (
            this.selectedRound.pools[i].groups.filter((group) =>
              group.playerConnections.some(
                (player) => player.playerId == this.selectedPlayer.id
              )
            ).length > 0
          ) {
            playersPoolsGroups = this.selectedRound.pools[i].groups;
            for (var ii = 0; ii < playersPoolsGroups.length; ii++) {
              if (
                playersPoolsGroups[ii].playerConnections.filter(
                  (player) => player.playerId == this.selectedPlayer.id
                ).length == 1
              ) {
                groupId = playersPoolsGroups[ii].id;
                break;
              }
            }
          }
        }

        this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
            mutation CreateResult($CreateResult: ResultInput!){
              CreateResult(playerId:"${this.selectedPlayer.id}", groupId:"${groupId}", holeId:"${hole.id}",asRole:TOUR_ADMIN,input:$CreateResult){
                id
              }
            }
            `,
            variables: {
              CreateResult: {
                score: hole.result.score,
                isCircleHit: hole.result.isCircleHit,
                isInsidePutt: hole.result.isInsidePutt,
                isOutsidePutt: hole.result.isOutsidePutt,
                isOutOfBounds: hole.result.isOutOfBounds,
              },
            },
          },
        })
          .then(() => {
            this.$store.dispatch("showMessage", {
              show: true,
              message: "Saved",
              type: "success",
            });
          })
          .catch(() => {
            this.$store.dispatch("showMessage", {
              show: true,
              message: "Error",
              type: "error",
            });
          });
      }
    },
    decreaseScore(hole) {
      if (hole.result.score > 1) {
        return hole.result.score--;
      }
    },
    increaseScore(hole) {
      if (hole.result.score < 999) {
        return hole.result.score++;
      }
    },
    parColor(hole) {
      if (hole.result.score == 0) return "par";
      else if (hole.result.score == 1) return "ace";
      else if (hole.result.score - hole.par <= -3) return "albatross";
      else if (hole.result.score - hole.par == -2) return "eagle";
      else if (hole.result.score - hole.par == -1) return "birdie";
      else if (hole.result.score - hole.par == 0) return "par";
      else if (hole.result.score - hole.par == 1) return "bogey";
      else if (hole.result.score - hole.par == 2) return "doubleBogey";
      else if (hole.result.score - hole.par >= 3) return "tripleBogey";
    },
    findPlayersPool(selectedPlayer, round) {
      let playersPool = "";

      for (var i = 0; i < round.pools.length; i++) {
        if (
          round.pools[i].groups.filter((group) =>
            group.playerConnections.some(
              (player) => player.playerId == selectedPlayer.id
            )
          ).length > 0
        ) {
          playersPool = round.pools[i].id;
          break;
        }
      }

      this.loadPlayerResults(this.selectedPlayer, playersPool);
    },
    loadPlayerResults(selectedPlayer, poolId) {
      this.$axios({
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        data: {
          query: `
          {
            pool(poolId:"${poolId}"){
              layoutVersion{
                holes{
                  id
                  number
                  par
                }
                layout{
                  course{
                    name
                  }

                }
              }
              leaderboard{
                id
                name
                type
                players{
                  playerId
          				firstName
                  lastName
                  results{
                    id
                    score
                    isCircleHit
                    isOutsidePutt
                    isInsidePutt
                    isOutOfBounds
                    hole{
                      id
                      number
                      name
                      par
                    }
                  }
                }
              }
            }
          }
          `,
        },
      })
        .then((result) => {
          result.data.data.pool.leaderboard.forEach((leaderboard) => {
            leaderboard.players.forEach((player) => {
              if (player.playerId == selectedPlayer.id) {
                this.playerResults = player;
              }
            });
          });

          let layout = result.data.data.pool.layoutVersion.holes;

          for (var i = 0; i < layout.length; i++) {
            let tempObject = this.playerResults.results.filter(
              (result) => result.hole.id == layout[i].id
            )[0];

            if (typeof tempObject == "undefined") {
              layout[i].result = {
                score: 0,
                id: null,
                isCircleHit: false,
                isInsidePutt: false,
                isOutsidePutt: false,
                isOutOfBounds: false,
              };
            } else {
              layout[i].result = this.playerResults.results.filter(
                (result) => result.hole.id == layout[i].id
              )[0];
            }
          }

          this.playedLayout.holes = layout;
          this.scoresLoaded = true;
        })
        .catch(() => {
          this.playedLayout = { holes: [] };
          this.playerResults = {};
          this.scoresLoaded = false;
        });
    },
    async loadData() {
      try {
        let eventResults = await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
          {
            event(eventId:"${this.$router.currentRoute.params.eventId}"){
              tour{
                name
              }
              name
              rounds{
                status
                pools{
                  id
                  position
                  status
                  groups{
                    id
                    playerConnections{
                      playerId
                    }
                  }
                }
              }
              players{
                id
                divisionId
                dnf{
                  isDnf
                }
                dns{
                  isDns
                }
                user{
                  id
                  firstName
                  lastName
                  profile{
                    pdgaNumber
                  }
                }
              }
            }
          }
          `,
          },
        });

        let tiebreakerData = await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
          {
            event(eventId:"${this.$router.currentRoute.params.eventId}"){
              tour{
                divisions{
                  type
                  name
                  id
                }
              }
              tiebreaks{
                place
                id
                player{
                  id
                  division{
                    id
                    type
                    name
                  }
                  user{
                    firstName
                    lastName
                    profile{
                      pdgaNumber
                    }
                  }
                }
              }
              ties{
                place
                score
                players{
                  id
                  user{
                    firstName
                    lastName
                    profile
                    {
                      pdgaNumber
                    }
                  }
                }
                division{
                  type
                  name
                  id
                }
              }
            }
          }
          `,
          },
        });

        this.tiebreakerData = tiebreakerData.data.data.event;
        this.eventData = eventResults.data.data.event;
        this.loading = false;
        this.selectedRound = {};
        this.selectedPlayer = {};
      } catch (err) {
        console.log(err);
      }
    },
  },
  mounted() {
    this.loadData();
  },
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
a {
  color: $teal;

  transition: 0.3s;

  &:hover {
    color: $teal;
    text-decoration: underline;
  }
}

section {
  margin-top: 30px;

  &:last-of-type {
    margin-top: 60px;
  }
}
.tiebreaker-section {
  flex-wrap: wrap;
  h5 {
    width: 100%;
  }
}
.scores-ties-container {
  padding: 0 12px;
  .section {
    padding: 20px;
    background-color: white;
    border-radius: 4px;
    margin-bottom: 24px;
    box-shadow: 0 0 3px $sleet;
    h5 {
      font-size: 20px;
      margin-bottom: 24px;
      @include Gilroy-Bold;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    .sub-section {
      margin-top: 24px;
    }

    .content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      .note {
        margin-bottom: 16px;
      }

      &.fixed-height-100 {
        margin-bottom: 32px;
      }

      &.export {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;

        .context-menu {
          margin-left: auto;
        }

        p {
          margin-bottom: 32px;
          b {
            display: inline;
          }
        }
      }

      p {
        margin-bottom: 0;
        b {
          margin-bottom: 0;
        }
      }

      &.division {
        margin-bottom: 48px;

        .none {
          margin-top: 12px;
        }

        .position {
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-top: 24px;
        }
        .player {
          display: flex;
          justify-content: space-between;
          width: 100%;
          height: 48px;
          border: 1px solid $sleet;
          align-items: center;
          padding: 0 10px;
          margin-top: 16px;
          border-radius: 4px;
          @include Gilroy-Bold;
          transition: all ease 0.3s;
          cursor: pointer;

          &:hover {
            border-color: $ocean;
            svg {
              opacity: 1;
            }
          }

          svg {
            opacity: 0;
            transition: all ease 0.3s;
          }
        }
      }

      table {
        margin-bottom: 32px;
        margin-top: 16px;
        width: 100%;
        thead {
          th {
            font-size: 12px;
            @include Gilroy-Bold;
            color: $midnight;
            text-align: center;

            &.hole {
              width: 10%;
            }

            &.score {
              width: auto;
            }

            &.circle-hit {
              width: 12%;
              &::after {
                content: "ch";
              }
            }
            &.inside-putt {
              width: 12%;
              &::after {
                content: "ip";
              }
            }
            &.outside-putt {
              width: 12%;
              &::after {
                content: "op";
              }
            }
            &.out-of-bounds {
              width: 12%;
              &::after {
                content: "ob";
              }
            }
          }
        }
        tbody {
          tr {
            td {
              @include Gilroy-Bold;
              font-size: 16px;
              text-align: center;
              height: 60px;
              border-bottom: 1px solid $fog;

              &.circle-hit {
                cursor: pointer;
              }
              &.inside-putt {
                cursor: pointer;
              }
              &.outside-putt {
                cursor: pointer;
              }
              &.out-of-bounds {
                cursor: pointer;
              }

              &.score {
                div {
                  display: inline-flex;
                  height: 40px;
                  width: 40px;
                  border-radius: 4px;
                  align-items: center;
                  justify-content: center;
                  margin: 0 10px;
                }
              }

              svg {
                cursor: pointer;
                .a,
                .b {
                  fill: none;
                }
                .b {
                  stroke: #2d323c;
                }
              }
            }
          }
        }
        tfoot {
          td {
            text-align: center;
            font-size: 20px;
            @include Gilroy-Bold;
            height: 60px;
          }
        }
      }
      .buttons {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-top: 42px;

        .button-container {
          width: 45%;
        }
      }
      .dnf-dns-switches {
        display: flex;
        width: 100%;
        justify-content: space-between;

        span {
          display: flex;
          align-items: center;
        }
      }

      b {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        width: 100%;
      }
      p {
        margin: 0;

        &.warning {
          color: $dusk;
        }
      }

      .default-toggle {
        margin-left: 15px;
      }

      &.vertical {
        flex-direction: column;
        align-items: flex-start;
      }

      .archive {
        color: $teal;
        cursor: pointer;
      }
      .delete {
        color: $dusk;
        cursor: pointer;
      }

      .left {
        width: 100%;

        padding-bottom: 30px;
      }

      .right {
        display: flex;
        justify-content: space-between;
        width: 100%;
        flex-wrap: wrap;
        align-items: flex-start;
        padding-top: 30px;

        .default-toggle {
          margin-left: 0;
          margin-bottom: 1rem;
        }
      }
      .selection-boxes {
        display: flex;
        width: 100%;
        margin-top: 20px;

        ul {
          list-style: none;
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          width: 100%;
          margin: 0;
          padding: 0;

          li {
            border-radius: 6px;
            height: 48px;
            width: 48px;
            border: 1px solid $sleet;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $sleet;
            margin-right: 15px;
            margin-bottom: 15px;
            transition: 0.3s;
            cursor: pointer;
            &:hover {
              border: 1px solid $midnight;
              transition: 0.3s;
            }

            &.active {
              border: 1px solid $ocean;
              background-color: $ocean;
              color: white;
              transition: 0.3s;
              @include Gilroy-Bold;

              &:hover {
                background-color: $tealhover;
                transition: 0.3s;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 1200px) {
  .context-menu {
    width: auto;
  }
  section {
    width: calc(50% - 50px);
    margin-top: 0px;

    &:last-of-type {
      margin-top: 0px;
    }
  }

  .tiebreaker-section {
    display: flex;
    justify-content: space-between;
  }
  .scores-ties-container {
    padding: 0;
    .section {
      padding: 30px 30px 40px 30px;
      background-color: white;
      border-radius: 10px;
      box-shadow: 0 0 3px $sleet;
      margin-bottom: 24px;

      .content {
        margin: 0 0 20px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .note {
          margin-bottom: 0px;
        }

        &.export {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          p {
            width: 50%;
            margin-bottom: 0;
            b {
              display: inline;
            }
          }
        }

        &.division {
          .position {
            margin-top: 42px;

            &:nth-of-type(1) {
              margin-top: 16px;
            }
          }
        }

        &.fixed-height-100 {
          height: 150px;
          align-items: flex-start;
          flex-direction: column;
          justify-content: flex-start;
        }

        .buttons {
          justify-content: flex-end;
          width: 50%;

          .button-container {
            width: auto;
            margin-left: 32px;
          }
        }
        .dnf-dns-switches {
          width: auto;
          span {
            margin-right: 42px;
          }
        }

        .players {
          width: 70%;
          margin-right: 24px;
          margin: 0px;
        }
        .rounds {
          margin-right: auto;
          width: 20%;
          margin: 0px;
        }
        h3 {
          font-size: 48px;
          margin: 0px;
        }

        table {
          margin-top: 32px;
          thead {
            th {
              font-size: 12px;
              @include Gilroy-Bold;
              color: $midnight;
              text-align: center;
              width: auto;

              &.hole {
                width: 5%;
              }
              &.score {
                width: 15%;
              }

              &.circle-hit::after {
                content: "circle hit";
              }
              &.inside-putt::after {
                content: "inside putt";
              }
              &.outside-putt::after {
                content: "outside putt";
              }
              &.out-of-bounds::after {
                content: "ob";
              }
            }
          }
          tbody {
            tr {
              td {
                @include Gilroy-Bold;
                font-size: 16px;
                text-align: center;
              }
            }
          }
        }

        b {
          width: 100%;
        }

        .left {
          width: 50%;
          border-right: 1px solid $sleet;
        }

        .right {
          display: flex;
          justify-content: space-between;
          padding-left: 40px;
          width: 50%;
          flex-wrap: wrap;
          align-items: flex-start;
          padding-top: 0;

          .selection-boxes {
            display: flex;
            width: 100%;
            margin-top: 20px;

            ul {
              list-style: none;
              display: flex;
              justify-content: flex-start;
              flex-wrap: wrap;
              width: 100%;
              margin: 0;
              padding: 0;

              li {
                border-radius: 6px;
                height: 40px;
                width: 40px;
                border: 1px solid $sleet;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $sleet;
                margin-right: 15px;
                margin-bottom: 15px;
                transition: 0.3s;

                &.active {
                  border: 1px solid $ocean;
                  background-color: $ocean;
                  color: white;
                  transition: 0.3s;
                  @include Gilroy-Bold;
                }
              }
            }
          }
        }

        &.vertical {
          flex-direction: column;
          align-items: flex-start;
        }
      }
      select {
        max-width: calc(50% - 20px);
      }
    }
  }
  h5 {
    font-size: 20px;
    margin-bottom: 30px;
  }
}
</style>
