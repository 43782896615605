<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="28.828" viewBox="0 0 24 28.828">
    <g transform="translate(-725 -1238.586)">
      <path class="a" d="M-640.554-9047h24" transform="translate(1365.553 10300)" />
      <line class="a" y1="8" transform="translate(737 1241)" />
      <path class="a" d="M-669.985-9057.9l6-6,6,6" transform="translate(1400.985 10303.896)" />
      <g transform="translate(731 1257)">
        <line class="a" y2="8" transform="translate(6)" />
        <path class="a" d="M-669.985-9063.9l6,6,6-6" transform="translate(669.985 9066.896)" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "Tiebreaker",
};
</script>

<style lang="scss" scoped>
.a {
  fill: none;
  stroke: #05b5bc;
  stroke-width: 2px;
}
</style>